import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// "admin_errors_watch" на проверку этих прав повешано много ненужных для локального админа страниц
const routes = [
  {
    path: "/providers-profile/:id",
    name: "Профиль",
    component: () => import("../views/Providers/ProviderProfile"),
    meta: {
      icon: "mdi-account-circle",
      provider: true,
      dinamicRoute: true,
    },
  },
  // управление заказами
  {
    path: "/",
    name: "Заказы",
    component: () => import("../views/Orders/Order.vue"),
    meta: {
      icon: "mdi-order-bool-descending-variant",
      power: "admin_order_watch",
      group: "Управление заказами",
      provider: true,
    },
  },
  {
    path: "/order-statistics",
    name: "Данные по заказам",
    component: () => import("../views/Orders/OrderStatistics.vue"),
    meta: {
      icon: "mdi-database-eye-outline",
      power: "admin_order_watch",
      group: "Управление заказами",
    },
  },
  {
    path: "/map",
    name: "Карта",
    component: () => import("../views/Map.vue"),
    meta: {
      icon: "mdi-map",
      power: "admin_map_watch",
      group: "Управление заказами",
    },
  },
  /////////////
  {
    path: "/category/new",
    name: "Добавление категории",
    component: () => import("../views/Category/SingleCategory"),
  },
  {
    path: "/category/:id",
    name: "Изменение категории",
    component: () => import("../views/Category/SingleCategory"),
  },

  {
    path: "/employee/new",
    name: "Добавление сотрудников",
    component: () => import("../views/Employee/SingleEmployee"),
  },
  {
    path: "/employee/:id(\\d+)",
    name: "Изменение сотрудника",
    component: () => import("../views/Employee/SingleEmployee"),
  },
  // Управление персоналом
  {
    path: "/employee",
    name: "Сотрудники",
    component: () => import("../views/Employee/Employee"),
    meta: {
      icon: "mdi-account-cowboy-hat",
      power: "admin_employee_watch",
      group: "Управление персоналом",
      provider: true,
    },
  },
  {
    path: "/employee/rating",
    name: "Рейтинг Премий",
    component: () => import("../views/Employee/EmployeeRating"),
    meta: {
      icon: "mdi-archive-star",
      power: "admin_employee_schedule_watch",
      group: "Управление персоналом",
    },
  },
  {
    path: "/schedule",
    name: "Табель",
    component: () => import("../views/Schedules"),
    meta: {
      icon: "mdi-table",
      power: "admin_employee_schedule_watch",
      group: "Управление персоналом",
    },
  },
  {
    path: "/timetable",
    name: "График работы",
    component: () => import("../views/Timetable"),
    meta: {
      icon: "mdi-clock",
      power: "admin_employee_schedule_watch",
      group: "Управление персоналом",
    },
  },
  {
    path: "/education",
    name: "Тесты",
    component: () => import("../views/Education"),
    meta: {
      icon: "mdi-school",
      power: "admin_employee_schedule_watch",
      group: "Управление персоналом",
    },
  },
  {
    path: "/penalties",
    name: "Штрафы",
    component: () => import("../views/Penalties"),
    meta: {
      icon: "mdi-currency-usd-off",
      power: "admin_employee_watch",
      group: "Управление персоналом",
    },
  },
  ////////////////////////////////////////
  {
    path: "/timetable/new",
    name: "Создание графика работы",
    component: () => import("../views/SingleTimetable"),
  },
  {
    path: "/timetable/:id",
    name: "Изменение графика работы",
    component: () => import("../views/SingleTimetable"),
  },
  {
    path: "/area/new",
    name: "Добавление зоны доставки",
    component: () => import("../views/SingleArea"),
  },
  {
    path: "/area/:id",
    name: "Редактирование зоны доставки",
    component: () => import("../views/SingleArea"),
  },
  {
    path: "/measure/new",
    name: "Добавление измерения",
    component: () => import("../views/SingleMeasure"),
  },
  {
    path: "/measure/:id",
    name: "Изменение измерения",
    component: () => import("../views/SingleMeasure"),
  },
  {
    path: "/file",
    name: "Отчеты",
    component: () => import("../views/File"),
    meta: {
      icon: "mdi-file",
      power: "admin_reports_watch",
      group: "Отчеты",
      provider: true,
    },
  },
  {
    path: "/products/create",
    name: "Создание продукта",
    component: () => import("../views/Products/SingleProduct"),
  },
  {
    path: "/products/:id",
    name: "Изменение продукта",
    component: () => import("../views/Products/SingleProduct"),
  },
  {
    path: "/product-difference/:id",
    name: "Разница изменений",
    component: () => import("../views/Products/ProductDifference"),
  },
  // {
  //   path: "/city",
  //   name: "Города",
  //   component: () => import("../views/City"),
  //   meta: {
  //     icon: "mdi-city",
  //   },
  // },
  // {
  //   path: "/city/new",
  //   name: "Добавление города",
  //   component: () => import("../views/SingleCity"),
  // },
  // {
  //   path: "/city/:id",
  //   name: "Изменение города",
  //   component: () => import("../views/SingleCity"),
  // },
  // {
  //   path: "/currency",
  //   name: "Валюты",
  //   component: () => import("../views/Currency"),
  //   meta: {
  //     icon: "mdi-currency-kzt",
  //   },
  // },
  // {
  //   path: "/currency/new",
  //   name: "Добавление валюты",
  //   component: () => import("../views/SingleCurency"),
  // },
  {
    path: "/department/new",
    name: "Добавление филиала",
    component: () => import("../views/Departments/SingleDepartment"),
  },
  {
    path: "/department/:id",
    name: "Изменение филиала",
    component: () => import("../views/Departments/SingleDepartment"),
  },
  {
    path: "/power/new",
    name: "Добавление прав",
    component: () => import("../views/SinglePower"),
  },
  {
    path: "/power/:id",
    name: "Изменение прав",
    component: () => import("../views/SinglePower"),
  },
  {
    path: "/parameters-delivery-control/new",
    name: "Добавление контакта доставки",
    component: () => import("../views/SingleParameterDeliveryControl"),
  },
  {
    path: "/parameters-hotline-control/new",
    name: "Добавление контакта горячей линии",
    component: () => import("../views/SingleParameterHotlineControl"),
  },
  // Управление зонами и филиалами
  {
    path: "/area",
    name: "Зоны доставки",
    component: () => import("../views/Area"),
    meta: {
      icon: "mdi-map-marker",
      power: "admin_area_watch",
      group: "Зоны и филиалы",
    },
  },
  {
    path: "/department",
    name: "Филиалы",
    component: () => import("../views/Departments/Departments"),
    meta: {
      icon: "mdi-office-building-marker",
      power: "admin_department_watch",
      group: "Зоны и филиалы",
      provider: true,
    },
  },
  {
    path: "/parameters-sms-control",
    name: "СМС код активации",
    component: () => import("../views/ParameterSmsControl"),
    meta: {
      power: "admin_department_watch",
      icon: "mdi-message-alert",
      group: "Зоны и филиалы",
    },
  },
  {
    path: "/parameters-hotline-control",
    name: "Номера горячей линии",
    component: () => import("../views/ParameterHotlineControl"),
    meta: {
      power: "admin_department_watch",
      icon: "mdi-archive-cog",
      group: "Зоны и филиалы",
    },
  },
  {
    path: "/parametersproduct-overflow-limit",
    name: "Вакансии",
    component: () => import("../views/ParameterVacancyControl"),
    meta: {
      power: "admin_vacancy_control",
      icon: "mdi-archive-cog",
      group: "Зоны и филиалы",
    },
  },
  {
    path: "/parameters-time-control/:id",
    name: "Время работы филиалов",
    component: () => import("../views/TimeControl"),
    meta: {
      icon: "mdi-archive-cog",
      power: "admin_errors_watch",
      group: "Зоны и филиалы",
      provider: true,
    },
  },
  {
    path: "/parameters-delivery-control",
    name: "Номера Доставки",
    component: () => import("../views/ParameterDeliveryControl"),
    meta: {
      power: "admin_department_watch",
      icon: "mdi-archive-cog",
      group: "Зоны и филиалы",
    },
  },
  {
    path: "/department-profile/:id",
    name: "Управление филиалом",
    component: () => import("../views/Departments/DepartmentForProvider"),
  },
  //////////////////////////////////////
  // Управление доступом
  {
    path: "/roles",
    name: "Роли",
    component: () => import("../views/Roles"),
    meta: {
      icon: "mdi-account-hard-hat",
      power: "admin_role_watch",
      group: "Управление доступом",
    },
  },
  {
    path: "/users",
    name: "Пользователи",
    component: () => import("../views/Users"),
    meta: {
      icon: "mdi-account-tie",
      power: "admin_user_watch",
      group: "Управление доступом",
    },
  },
  {
    path: "/power",
    name: "Права",
    component: () => import("../views/Power"),
    meta: {
      icon: "mdi-account-network",
      power: "admin_power_watch",
      group: "Управление доступом",
    },
  },
  ///////////////////////////////////////
  {
    path: "/how-work",
    name: "Как работать",
    component: () => import("../views/HowWork"),
  },
  {
    path: "/roles/new",
    name: "Добавление роли",
    component: () => import("../views/SingleRole"),
  },
  {
    path: "/roles/:id",
    name: "Изменение роли",
    component: () => import("../views/SingleRole"),
  },
  {
    path: "/users/new",
    name: "Создание пользователей",
    component: () => import("../views/SingleUser"),
  },
  {
    path: "/users/:id",
    name: "Изменение пользователей",
    component: () => import("../views/SingleUser"),
  },
  {
    path: "/employee-profile/:id",
    name: "Профиль сотрудника",
    component: () => import("../views/Employee/EmployeeProfile.vue"),
  },
  {
    path: "/login",
    name: "Авторизация",
    component: () => import("../views/SingIn"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/order/create",
    name: "Создание заказа",
    component: () => import("../views/Orders/SingleOrder"),
    meta: {
      button: "Создать",
    },
  },
  {
    path: "/order/:id",
    name: "Изменение заказа",
    component: () => import("../views/Orders/SingleOrder"),
    meta: {
      button: "Изменить",
    },
  },
  // Управление контентом
  {
    path: "/products-moderation",
    name: "Модерация продуктов",
    component: () => import("../views/Products/ProductsModeration"),
    meta: {
      power: "admin_errors_watch",
      icon: "mdi-scale-balance",
      group: "Управление контентом",
    },
  },
  {
    path: "/features",
    name: "Характеристики",
    component: () => import("../views/Features/Features"),
    meta: {
      power: "admin_errors_watch",
      icon: "mdi-alpha-f-box-outline",
      group: "Управление контентом",
    },
  },
  {
    path: "/staticpage",
    name: "Страницы",
    component: () => import("../views/StaticPage"),
    meta: {
      power: "admin_user_watch",
      icon: "mdi-page-next",
      group: "Управление контентом",
    },
  },
  {
    path: "/category",
    name: "Категории",
    component: () => import("../views/Category/Category"),
    meta: {
      power: "admin_category_watch",
      icon: "mdi-view-list",
      group: "Управление контентом",
    },
  },
  {
    path: "/banners",
    name: "Баннеры",
    component: () => import("../views/Banners"),
    meta: {
      power: "admin_order_watch",
      icon: "mdi-image-area",
      group: "Управление контентом",
    },
  },
  {
    path: "/sitecategory",
    name: "Витрина",
    component: () => import("../views/SiteCategory"),
    meta: {
      power: "admin_site_category_watch",
      icon: "mdi-sitemap",
      group: "Управление контентом",
    },
  },
  {
    path: "/products-mvp",
    name: "Товары",
    component: () => import("../views/Products/ProductsMVP"),
    meta: {
      power: "admin_product_watch",
      icon: "mdi-bowl-mix",
      group: "Управление контентом",
    },
  },
  {
    path: "/deleted-products",
    name: "Удаленные товары",
    component: () => import("../views/DeletedProducts"),
    meta: {
      icon: "mdi-order-bool-descending-variant",
      power: "admin_errors_watch",
      group: "Управление контентом",
    },
  },
  /////////////////////////////////////////////
  {
    path: "/staticpage/new",
    name: "Добавление статических страниц",
    component: () => import("../views/SingleStatic"),
  },
  {
    path: "/staticpage/:id",
    name: "Изменение статических страниц",
    component: () => import("../views/SingleStatic"),
  },
  {
    path: "/banners/add",
    name: "Добавление баннера",
    component: () => import("../views/SingleBanner"),
  },
  {
    path: "/banners/edit/:id",
    name: "Редактирование баннера",
    component: () => import("../views/SingleBanner"),
  },
  {
    path: "/catalog",
    name: "Очередность каталога",
    component: () => import("../views/Category/Catalog"),
  },

  // Справочники
  {
    path: "/stack-types",
    name: "Спр. оборудования",
    component: () => import("../views/StackTypes"),
    meta: {
      icon: "mdi-stack-overflow",
      power: "admin_order_watch",
      group: "Справочники",
    },
  },
  {
    path: "/measure",
    name: "Единицы измерения",
    component: () => import("../views/Measure"),
    meta: {
      icon: "mdi-ruler",
      power: "admin_measure_watch",
      group: "Справочники",
    },
  },
  {
    path: "/reports",
    name: "Ошибки",
    component: () => import("../views/Report.vue"),
    meta: {
      icon: "mdi-alert",
      power: "admin_errors_watch",
      group: "Справочники",
    },
  },
  {
    path: "/tags",
    name: "Тэги",
    component: () => import("../views/Tags"),
    meta: {
      icon: "mdi-tag-plus",
      power: "admin_errors_watch",
      group: "Справочники",
    },
  },
  {
    path: "/push-notify",
    name: "Push-уведомления",
    component: () => import("../views/PushNotifications"),
    meta: {
      icon: "mdi-bell-cog",
      power: "admin_errors_watch",
      group: "Справочники",
    },
  },
  {
    path: "/product-overflow-limit",
    name: "Лимиты",
    component: () => import("../views/ParameterLimitControl"),
    meta: {
      power: "admin_errors_watch",
      icon: "mdi-archive-cog",
      group: "Справочники",
    },
  },
  // {
  //   path: "/order-statistics-map",
  //   name: "Карта статистики",
  //   component: () => import("../views/OrderStatisticsMap.vue"),
  //   meta: {
  //     icon: "mdi-map",
  //     power: "admin_map_watch",
  //     group: "Справочники",
  //   },
  // },
  //////////////////////////////////////////

  {
    path: "/404",
    name: "404",
    component: () => import("../views/404"),
  },
  {
    path: "*",
    redirect: "/404",
  },
  // Отзывы и оценки
  {
    path: "/reviews",
    name: "Отзывы",
    component: () => import("../views/Review"),
    meta: {
      icon: "mdi-message-draw",
      power: "admin_errors_watch",
      group: "Отзывы и оценки",
    },
  },
  ///////////////

  // Логистика
  {
    path: "/logistic",
    name: "Управление логистикой",
    component: () => import("../views/Logistic"),
    meta: {
      icon: "mdi-size-l",
      power: "admin_errors_watch",
      group: "Управление логистикой",
    },
  },
  {
    path: "/stackSystem",
    name: "Система стеллажей",
    component: () => import("../views/stackSystem"),
    meta: {
      icon: "mdi-stack-overflow",
      power: "admin_errors_watch",
      group: "Управление логистикой",
    },
  },
  {
    path: "/stackSystem/excluded-queue",
    name: "Очередь исключений",
    component: () => import("../views/StackSystemExcludedQueue"),
    meta: {
      power: "admin_order_watch",
    },
  },
  {
    path: "/courier-types",
    name: "Типы курьеров",
    component: () => import("../views/Couriers/CourierTypes"),
    meta: {
      power: "admin_order_watch",
      icon: "mdi-bike",
      group: "Управление логистикой",
    },
  },
  {
    path: "/couriers-list",
    name: "Список курьеров",
    component: () => import("../views/Couriers/CouriersList"),
    meta: {
      power: "admin_order_watch",
      icon: "mdi-bike-fast",
      group: "Управление логистикой",
    },
  },
  {
    path: "/couriers-area",
    name: "Зоны курьеров",
    component: () => import("../views/Couriers/CouriersArea"),
    meta: {
      power: "admin_order_watch",
      icon: "mdi-map-marker",
      group: "Управление логистикой",
    },
  },
  {
    path: "/couriers-area/new",
    name: "Создание зоны курьера",
    component: () => import("../views/Couriers/CouriersNewArea"),
  },
  // Поставщики
  {
    path: "/providers-list",
    name: "Поставщики",
    component: () => import("../views/Providers/ProvidersList"),
    meta: {
      icon: "mdi-account-group",
      power: "admin_errors_watch",
      group: "Управление вендорами",
    },
  },
  {
    path: "/redact-product-price/:id",
    name: "Редактирование цен",
    component: () => import("../views/Providers/RedactPrice"),
  },
  {
    path: "/search-product/:providerId",
    name: "Поиск продуктов",
    component: () => import("../views/Products/ProductsSearch"),
  },
  ////////
  {
    path: "/providers-list/:id",
    name: "Товары.",
    component: () => import("../views/Providers/ProviderProducts"),
    meta: {
      icon: "mdi-bowl-mix",
      provider: true,
      dinamicRoute: true,
    },
  },
  {
    path: "/providers-limit/:id",
    name: "Лимиты поставщика",
    component: () => import("../views/Providers/ProviderLimit"),
  },

  {
    path: "/couriers-area/:id",
    name: "Информация зоны доставки",
    component: () => import("../views/Couriers/CouriersNewArea"),
  },
  // пути Астора
  {
    path: "/astor",
    name: "АСТОР",
    component: () => import("../views/SignInASTOR"),
    meta: {
      layout: "Astor",
    },
  },
  {
    path: "/astor/files",
    name: "АСТОР Файлы и их изменения",
    component: () => import("../views/FilesASTOR"),
    meta: {
      name: "Файлы",
      icon: "mdi-file-alert",
      power: "admin_errors_watch",
      group: "Astor",
    },
  },
  {
    path: "/astor/sku-price",
    name: "Логи изменений SkuPrice",
    component: () => import("../views/LogsASTOR"),
    meta: {
      name: "SkuPrice",
      icon: "mdi-format-list-text",
      power: "admin_errors_watch",
      group: "Astor",
    },
  },
  {
    path: "/astor/product-catalog",
    name: "Логи изменений ProductCatalog",
    component: () => import("../views/LogsASTOR"),
    meta: {
      name: "Продукты",
      icon: "mdi-bowl-mix",
      power: "admin_errors_watch",
      group: "Astor",
    },
  },
  {
    path: "/chat",
    name: "Чат",
    component: () => import("../views/Chat/Chat.vue"),
    meta: {
      power: "admin_chat_watch",
      icon: "mdi-chat",
      group: "Чат",
    },
  },
  {
    path: "/chat-tags",
    name: "Теги для чата",
    component: () => import("../views/Chat/ChatTags.vue"),
    meta: {
      power: "admin_chat_watch",
      icon: "mdi-tag-plus",
      group: "Чат",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // if (to.name === "Чат c токеном") {
  //   let token = to.params.token;
  //   if (token) {
  //     Vue.prototype.$cookie.set("token", "Bearer " + token);
  //   }
  // }
  // next();
  if (to.path !== "/login" && !Vue.prototype.$cookie.get("token")) {
    if (to.path == "/astor") {
      next();
    } else {
      next({ path: "/login" });
    }
  } else next();
});
export default router;
